import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import SiteHeader from "../components/SiteHeader";

import { makeStyles } from "@material-ui/core/styles";
import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({ root: { flexGrow: 1 } }));

const ContactUs = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container maxWidth="sm" style={{ textAlign: "center" }}>
        <SiteHeader />
        <br />
        <br />
        <br />
        <Typography variant="subtitle1" gutterBottom>
          Contact us at{" "}
          <a href="mailto:support@pooflinks.com">support@pooflinks.com</a>.
        </Typography>
        <br />
        <div className={classes.root}>
          <Footer {...props} />
        </div>
      </Container>
    </React.Fragment>
  );
};

export default ContactUs;
