import React, { Fragment } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import BackLink from "../components/BackLink";
import Copyright from "../components/Copyright";

const PrivacyPolicy = (props) => {
  return (
    <Container
      maxWidth="md"
      style={{ textAlign: "left", paddingLeft: "2rem", paddingRight: "2rem" }}
    >
      <Typography
        variant="h3"
        align="center"
        style={{ marginTop: "3rem", marginBottom: "3rem" }}
        gutterBottom
      >
        Privacy Policy
      </Typography>
      <BackLink {...props} />
      <Typography variant="subtitle1" gutterBottom>
        <em>Effective: November 18, 2020</em>
      </Typography>
      <br />
      <Typography variant="subtitle1" gutterBottom>
        We care deeply about protecting your private information. It's literally
        our <em>only job</em>, and we strive to do it well.
      </Typography>
      <br />
      {[
        {
          header: "Data we collect",
          body:
            "Unsurprisingly, we collect the secret messages you submit to us. While we don't use cookies, we do log IP addresses to monitor usage and prevent abuse.",
        },
        {
          header: "How we collect data",
          body:
            "When you share a secret message with us, we use SSL to encrypt and securely transfer your data directly to our servers.",
        },
        {
          header: "How we protect data",
          body:
            "When we receive a message submission, we immediately secure it with AES-256 (a.k.a. 'military-grade') encryption using a randomly generated symmetric key and initialization vector for each message. Your secret message is never written into our server logs, and we never write unencrypted messages to our databases. We embed the key in the provided url and don't store keys in our databases for www.pooflinks.com and any of our browser extensions. When interacting with intermediary applications (e.g. Slack) that use the Poof API to generate secure messages for users, we withhold and store half of a key as a security precaution.",
        },
        {
          body:
            "To keep your secret as safe as possible, never reveal the URL to anyone other than the intended recipient. If you lose your URL, we cannot retrieve your secret for you.",
        },
        {
          header: "How we manage data",
          body:
            "Your data stays on our servers - we don't sell or lease your data to any third party. A secret message can only be retrieved within the window of time specified by the submitting party, up to a maximum of 7 days, and secrets that have not been retrieved within the designated window are considered 'expired'. Secrets are wiped from our servers once viewed, and secrets that expire are immediately inaccessible and are permanently removed from our database within two (2) minutes of their expiration. ",
        },
        {
          header: "How you can control your data",
          body:
            "If you want a secret immediately removed from our servers, either (1) use the 'Destroy Link' button visible immediately after a secure link is created on www.pooflinks.com, or (2) view the message. Once either of these things happens, the message can no longer be retrieved and is permanently removed from our servers.",
        },
      ].map(({ header, body }) => (
        <Fragment>
          {header && (
            <Typography variant="h5" gutterBottom>
              {header}
            </Typography>
          )}
          <Typography variant="subtitle1" gutterBottom>
            {body}
          </Typography>
          <br />
        </Fragment>
      ))}

      <Typography variant="subtitle1" gutterBottom>
        If you have any further questions, please feel free to contact us at{" "}
        <a href="mailto:privacy@pooflinks.com">privacy@pooflinks.com</a>.
      </Typography>
      <BackLink {...props} />
      <Typography
        style={{ marginTop: "2rem", marginBottom: "1.5rem" }}
        variant="subtitle2"
        align="center"
        gutterBottom
      >
        <Copyright />
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
