import React, { useState, useEffect, Fragment } from "react";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import SiteHeader from "../components/SiteHeader";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import * as api from "../utils/api";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const SecretMessage = (props) => {
  const { urlId, key } = useParams();
  const [secretExists, setSecretExists] = useState(null);
  const [cleartext, setCleartext] = useState(null);
  const [error, setError] = useState(null);

  const redirect = () => props.history.push("/");
  async function showSecret() {
    const response = await api.getSecret(urlId, key);

    if (response.timeout) {
      return setError(
        "We are unable to connect to our servers at this time. Please try again later."
      );
    }

    if (!response.success) {
      // Failure
      setCleartext("[ THIS SECRET HAS ALREADY BEEN VIEWED ]");
    } else {
      // Successful response
      const { cleartext } = response.data || {
        cleartext: "Apologies, we were unable to retrieve your message.",
      };
      setCleartext(cleartext);
    }
  }

  useEffect(() => {
    // Get url paramaters
    async function getSecretStatus() {
      const response = await api.getSecretStatus(urlId);

      // Timeout
      if (response.timeout) {
        setError(
          "We are unable to connect to our servers at this time. Please try again later."
        );
      }

      const { exists } = response.data || { exists: false };
      setSecretExists(exists);
    }
    getSecretStatus();
    // eslint-disable-next-line
  }, []);

  if (secretExists === null) return <LinearProgress />;

  return (
    <React.Fragment>
      <Container maxWidth="sm" style={{ textAlign: "center" }}>
        <SiteHeader />
        {error ? (
          <ShowError error={error} />
        ) : secretExists ? (
          <SecretExists {...{ cleartext, showSecret, redirect }} />
        ) : (
          <SecretDoesNotExist redirect={redirect} />
        )}
      </Container>
    </React.Fragment>
  );
};

function ShowError({ error }) {
  return (
    <Fragment>
      <div style={{ marginTop: "3rem", color: "red" }}>
        <em>{error}</em>
      </div>
      {/* <Button variant="contained" disabled style={{ marginTop: "0.5rem" }}>
        Report Issue
      </Button> */}
    </Fragment>
  );
}

function SecretExists({ showSecret, ...props }) {
  return (
    <Fragment>
      {props.cleartext ? (
        <ShowCleartext {...props} />
      ) : (
        <ShowWarning showSecret={showSecret} />
      )}
    </Fragment>
  );
}

function SecretDoesNotExist({ redirect }) {
  return (
    <Fragment>
      <div style={{ marginTop: "3rem" }}>
        <em>
          The content of this link has already been viewed (or has never
          existed)
        </em>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={redirect}
        style={{ marginTop: "0.5rem" }}
      >
        Create a Poof Link
      </Button>
    </Fragment>
  );
}

function ShowCleartext(props) {
  const classes = useStyles();

  return (
    <Fragment>
      <Card
        className={classes.root}
        style={{ marginTop: "2rem" }}
        variant="outlined"
      >
        <CardContent>
          <Typography
            style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
            variant="subtitle1"
            align="center"
          >
            {props.cleartext}
          </Typography>
        </CardContent>
      </Card>
      <Button
        variant="contained"
        onClick={props.redirect}
        style={{ marginTop: "0.5rem", marginBottom: "3rem" }}
      >
        Send a Reply
      </Button>
    </Fragment>
  );
}

function ShowWarning(props) {
  return (
    <Fragment>
      <div style={{ marginTop: "3rem" }}>
        <em>The content of this link can only be viewed once.</em>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={props.showSecret}
        style={{ marginTop: "0.5rem" }}
      >
        {/* 
        TODO: Prevent double-clicks! 
        If clicked twice, the second click shows an error message
        */}
        Open message
      </Button>
    </Fragment>
  );
}

export default SecretMessage;
