import React, { Fragment } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import BackLink from "../components/BackLink";
import Copyright from "../components/Copyright";

const FAQs = (props) => {
  return (
    <Container
      maxWidth="md"
      style={{ textAlign: "left", paddingLeft: "2rem", paddingRight: "2rem" }}
    >
      <Typography
        variant="h3"
        align="center"
        style={{ marginTop: "3rem", marginBottom: "3rem" }}
        gutterBottom
      >
        FAQs
      </Typography>
      <BackLink {...props} />
      <br />
      {[
        {
          header: "Can you read our messages?",
          body:
            "No. Your messages are encrypted and we don't store the decryption key - we embed it in the url so you and your intended recipient(s) are the only people that can decrypt and read the message. If you're using our Poof app for Slack, we withhold and store part of the key so people with access to Slack databases cannot obtain the full key and read your messages.",
        },
        {
          header: "Do you save messages once they've been viewed?",
          body: "No. Messages are deleted as soon as they've been viewed.",
        },
      ].map(({ header, body }) => (
        <Fragment>
          {header && (
            <Typography variant="h5" gutterBottom>
              {header}
            </Typography>
          )}
          <Typography variant="subtitle1" gutterBottom>
            {body}
          </Typography>
          <br />
        </Fragment>
      ))}
      <br />
      <br />
      <br />
      <Typography variant="subtitle1" gutterBottom>
        If you have any further questions, please feel free to contact us at{" "}
        <a href="mailto:privacy@pooflinks.com">support@pooflinks.com</a>.
      </Typography>
      <BackLink {...props} />
      <Typography
        style={{ marginTop: "2rem", marginBottom: "1.5rem" }}
        variant="subtitle2"
        align="center"
        gutterBottom
      >
        <Copyright />
      </Typography>
    </Container>
  );
};

export default FAQs;
