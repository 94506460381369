import React, { Fragment } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import SiteHeader from "../components/SiteHeader";

import { makeStyles } from "@material-ui/core/styles";
import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({ root: { flexGrow: 1 } }));

const SlackInstall = (props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Container maxWidth="sm" style={{ textAlign: "center" }}>
        <SiteHeader />
        <InstallOnSlack />
        <div className={classes.root}>
          <Footer {...props} />
        </div>
      </Container>
    </Fragment>
  );
};

const InstallOnSlack = () => (
  <Fragment>
    <br />
    <br />
    <a href="https://slack.com/oauth/v2/authorize?scope=commands%2Cchat%3Awrite%2Cchat%3Awrite.public&state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbnN0YWxsT3B0aW9ucyI6eyJzY29wZXMiOlsiY29tbWFuZHMiLCJjaGF0OndyaXRlIiwiY2hhdDp3cml0ZS5wdWJsaWMiXX0sIm5vdyI6IjIwMjAtMTItMzFUMDU6MjY6MjcuODgxWiIsImlhdCI6MTYwOTM5MjM4N30.REDOvEX4sBxDAN-PjcorTLQuqDYHqVo92HCWfw7smzc&client_id=1476804829207.1523444220994">
      <img
        alt="Add to Slack"
        height="40"
        width="139"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      />
    </a>
    <Typography variant="subtitle1" gutterBottom>
      Bring the magic of Poof Links to Slack!
      <br />
      <br />
      Using the '/poof' command, you can send your colleagues encrypted,
      self-destructing Slack messages that can only be viewed once.
    </Typography>
    <br />
  </Fragment>
);

export default SlackInstall;
