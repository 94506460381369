import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./views/Home";
import FAQs from "./views/FAQs";
import ContactUs from "./views/ContactUs";
import PrivacyPolicy from "./views/PrivacyPolicy";
import SecretMessage from "./views/SecretMessage";
import SlackInstall from "./views/SlackInstall";
import TermsAndConditions from "./views/TermsAndConditions";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/faq" component={FAQs} />
        <Route exact path="/faqs" component={FAQs} />
        <Route exact path="/support" component={FAQs} />
        <Route exact path="/frequently-asked-questions" component={FAQs} />

        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />

        <Route exact path="/slack/install" component={SlackInstall} />

        <Route exact path="/:urlId/:key" component={SecretMessage} />
      </Switch>
    </Router>
  );
}

export default App;
