import React, { Fragment, useState } from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import LinearProgress from "@material-ui/core/LinearProgress";
import copy from "copy-to-clipboard";
import * as api from "../utils/api";
import SiteHeader from "../components/SiteHeader";
import DeleteIcon from "@material-ui/icons/Delete";
import AdditonalOptions from "../components/AdditionalOptions";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const MAX_MESSAGE_LENGTH = 10000;
const DEFAULT_EXPIRES_IN = 60 * 30; // 30 minutes

const Home = (props) => {
  const classes = useStyles();
  const [secureLinkLoading, setSecureLinkLoading] = useState(false);
  const [secureLink, setSecureLink] = useState(null);
  const [linkCredentials, setLinkCredentials] = useState(null);
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(null);
  const [copied, setCopied] = useState(null);
  const [expiresIn, setExpiresIn] = useState(DEFAULT_EXPIRES_IN);
  const [optionsOpen, setOptionsOpen] = useState(false);

  function CopyLinkToClipboard() {
    const success = secureLink && copy(secureLink);
    setCopied(success);
    setTimeout(() => {
      setCopied(null);
    }, 5000);
  }

  async function createSecureLink() {
    // Validate message (length, min and max)
    if (!message) {
      setMessageError("Please enter a message");
    } else if (message.length > MAX_MESSAGE_LENGTH) {
      setMessageError(
        `Message can't be longer than ${MAX_MESSAGE_LENGTH} characters.`
      );
    } else {
      setSecureLinkLoading(true);
      const response = await api.createSecret(
        message,
        expiresIn || DEFAULT_EXPIRES_IN
      );
      if (response.timeout) {
        // Timeout
        setMessageError(
          "We are unable to connect to our servers at this time. Please try again later."
        );
      } else if (!response.success) {
        // Unsuccessful link creation
        setMessageError(response.error?.message || "Unable to create link...");
      } else {
        // Successfull link creation
        const { urlId, key } = response.data;
        setSecureLink(`${window.location.origin}/${urlId}/${key}`);
        setLinkCredentials({ urlId, key });
      }
      setSecureLinkLoading(false);
    }
  }

  async function deleteSecret() {
    const { urlId, key } = linkCredentials || {};
    if (urlId && key) {
      setSecureLinkLoading(true);

      const response = await api.deleteSecret(urlId, key);

      if (response.timeout) {
        setMessageError(
          "We are unable to connect to our servers at this time. Please try again later."
        );
      } else if (response.success === false) {
        // DELETE returns a 204 with no content, _unless_ something went wrong
        setMessageError(
          response.error?.message ||
            "Unable to destroy link - it may have already been deleted or viewed."
        );
      }
      clearData();
    }
  }

  function clearData() {
    setMessage("");
    setLinkCredentials(null);
    setSecureLink(null);
    setSecureLinkLoading(false);
  }

  function handleExpiresInChange(e) {
    setExpiresIn(e.target.value);
  }

  function changeExpiration(e) {
    e.preventDefault();
    setOptionsOpen(true);
  }

  return (
    <React.Fragment>
      {secureLinkLoading && (
        <div style={{ position: "fixed", top: 0, width: "100%" }}>
          <LinearProgress />
        </div>
      )}
      <Container maxWidth="sm" style={{ textAlign: "center" }}>
        <SiteHeader />
        <div className={classes.root}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <TextField
                id="secretMessage"
                label="Secret Message"
                placeholder="Shhh..."
                variant="outlined"
                error={messageError || message.length > MAX_MESSAGE_LENGTH}
                helperText={
                  messageError || `${message.length}/${MAX_MESSAGE_LENGTH}`
                }
                color="primary"
                value={message}
                onFocus={() => setMessageError(false)}
                onChange={(e) => setMessage(e.target.value)}
                disabled={!!secureLink}
                multiline
                style={{ marginTop: "2rem", width: "80%" }}
              />
            </Grid>
            <ExpiryWarning
              show={!messageError && !secureLink}
              expiresIn={expiresIn}
              allowUpdate={!secureLink}
              onClick={changeExpiration}
            />
            {secureLink && (
              <Grid item xs={12}>
                <TextField
                  id="secureLink"
                  label="Secure Link"
                  value={secureLink}
                  variant="outlined"
                  color="primary"
                  onClick={CopyLinkToClipboard}
                  error={copied === false}
                  helperText={
                    copied === null
                      ? "Click to copy"
                      : copied
                      ? "Copied!"
                      : "Unable to copy to clipboard..."
                  }
                  style={{ marginTop: "0.5rem", width: "80%" }}
                />
                <br />
                <ExpiryWarning
                  show={!messageError && secureLink}
                  expiresIn={expiresIn}
                  allowUpdate={!secureLink}
                  onClick={changeExpiration}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {secureLink ? (
                <Fragment>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={deleteSecret}
                    style={{ marginTop: "0.5rem" }}
                  >
                    {/* TODO: Prevent double-clicks! */}
                    <DeleteIcon /> Destroy Link
                  </Button>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={clearData}
                    // TODO: Re-focus on message input
                    style={{ marginTop: "0.5rem" }}
                  >
                    Create Another
                  </Button>
                </Fragment>
              ) : (
                <Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={createSecureLink}
                    disabled={secureLinkLoading}
                    style={{ marginTop: "0.5rem" }}
                  >
                    {secureLinkLoading
                      ? "Creating Secure Link"
                      : "Create Secure Link"}
                  </Button>
                </Fragment>
              )}
              <Footer {...props} />
            </Grid>
          </Grid>
        </div>
      </Container>
      <AdditonalOptions
        open={optionsOpen}
        expiresIn={expiresIn}
        onOpenChange={setOptionsOpen}
        onExpiresInChange={handleExpiresInChange}
      />
    </React.Fragment>
  );
};

function ExpiryWarning({ show, allowUpdate, expiresIn, onClick: handleClick }) {
  const expiryString = moment()
    .add(expiresIn || DEFAULT_EXPIRES_IN, "seconds")
    .fromNow(); // "30 minutes", "1 hour", "1 day"
  return (
    <Fragment>
      {show && (
        <div style={{ verticalAlign: "middle", padding: 8 }}>
          <span style={{ padding: 1 }}>
            This link will expire {expiryString}.<br></br>
            {allowUpdate && (
              <Link href="#update-time" onClick={handleClick}>
                (Change)
              </Link>
            )}
          </span>
        </div>
      )}
    </Fragment>
  );
}

export default Home;
