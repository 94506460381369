/**
 * @desc Poof Links API Client
 */
const axios = require("axios");

// Get a secret's status
export async function getSecretStatus(urlId) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/v1/secrets/${urlId}/status`;
  return __api("GET", url);
}

// Retrieve a secret message
export async function getSecret(urlId, key) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/v1/secrets/${urlId}`;
  return __api("POST", url, { key });
}

// Submit a secret message
export async function createSecret(cleartext, expiresIn) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/v1/secrets`;
  return __api("POST", url, { cleartext, expiresIn });
}

// Delete a secret
export async function deleteSecret(urlId, key) {
  const url = `${process.env.REACT_APP_BACKEND_URL}/v1/secrets/${urlId}`;
  return __api("DELETE", url, { key });
}

async function __api() {
  // GET: url, options
  // DELETE: url, data, options
  // POST: url, data, options
  try {
    let { method, url, data = {}, options } = __binArgsByMethod(...arguments);
    data = { ...data, __app: "POOFLINKS_WEBSITE" };

    const response = await axios({ method, url, data, options });
    return { timeout: false, ...response.data };
  } catch (err) {
    // Check for network timeout
    if (!err.response?.data?.error) return { timeout: true };
    return { timeout: false, ...err.response?.data };
  }
}

function __binArgsByMethod() {
  // Supported methods: GET, POST, DELETE
  const method = arguments[0];

  let url, options, data;
  switch (method) {
    default:
    case "GET": {
      [url, options] = [].slice.call(arguments, 1);
      break;
    }
    case "DELETE":
    case "POST": {
      [url, data, options] = [].slice.call(arguments, 1);
      break;
    }
  }
  return { method, url, data, options };
}
