import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormControl, InputLabel, makeStyles, Select } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function AdditonalOptions({
  open,
  expiresIn,
  onOpenChange: setOpen,
  onExpiresInChange: handleExpiresInChange,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Additional Options</DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="expiresIn">Expires in</InputLabel>
          <Select
            native
            variant="outlined"
            style={{ width: "100%" }}
            label="Expires in"
            value={expiresIn}
            onChange={handleExpiresInChange}
            inputProps={{
              name: "expiresIn",
              id: "expiresIn",
            }}
          >
            <option hidden aria-label="None" value={""} />
            <option value={60 * 7}>7 minutes</option>
            <option value={60 * 15}>15 minutes</option>
            <option value={60 * 30}>30 minutes</option>
            <option value={3600 * 1}>1 hour</option>
            <option value={3600 * 6}>6 hours</option>
            <option value={3600 * 12}>12 hours</option>
            <option value={86400}>1 day</option>
            <option value={86400 * 3}>3 days</option>
            <option value={86400 * 7}>7 days</option>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
