import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import CssBaseline from "@material-ui/core/CssBaseline";

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <App style={{ margin: "0px", padding: "0px" }} />
  </React.StrictMode>,
  document.getElementById("root")
);
