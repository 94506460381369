import React, { Fragment } from "react";
import Link from "@material-ui/core/Link";
import Copyright from "./Copyright";

function Footer(props) {
  return (
    <Fragment>
      <div style={{ marginTop: "2rem" }}>
        {/* TODO: */}
        {/* <Link href="#not-ready-yet">
          <em>About</em>
        </Link>
        {" | "} */}
        <Link
          href="#privacy-policy"
          onClick={(e) => {
            e.preventDefault();
            props.history.push(`/privacy-policy`);
          }}
        >
          <em>Privacy</em>
        </Link>
        {" | "}
        <Link
          href="#terms-and-conditions"
          onClick={(e) => {
            e.preventDefault();
            props.history.push(`/terms-and-conditions`);
          }}
        >
          <em>Terms</em>
        </Link>
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <Copyright />
      </div>
    </Fragment>
  );
}

export default Footer;
