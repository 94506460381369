import Typography from "@material-ui/core/Typography";
import React, { Fragment } from "react";

const SiteHeader = () => {
  return (
    <Fragment>
      <Typography
        variant="h3"
        align="center"
        style={{ marginTop: "3rem", marginBottom: 0 }}
        gutterBottom
      >
        Poof Links
      </Typography>
      <Typography
        variant="h6"
        align="center"
        style={{ marginTop: "0rem" }}
        gutterBottom
      >
        Send messages that disappear!
      </Typography>
      <p
        style={{
          maxWidth: 300,
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 0,
        }}
      >
        <em>
          Securely send confidential information that disappears after being
          viewed once
        </em>
      </p>
    </Fragment>
  );
};

export default SiteHeader;
