import { Link } from "react-router-dom";

function BackLink(props) {
  return (
    <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
      <Link
        href="#"
        style={{ textDecoration: "none" }}
        onClick={(e) => {
          e.preventDefault();
          props.history.push(`/`);
        }}
      >
        {"<< Back"} {/* TODO: Go back to the _actual_ previous page */}
      </Link>
    </div>
  );
}

export default BackLink;
